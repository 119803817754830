.home {
  overflow: hidden;
  
  &__hero {
    background: linear-gradient(to bottom, $lila, $lilaDark);
    position: relative;
    
    &__container {
      padding-top: 40px;
    }
  
    &__title {
      color: $white;
      font-weight: 700;
      margin: 0;
      font-size: 38px;
      margin-bottom: 180px;

      @include mq(md) {
        margin-bottom: 20px;
        width: 45%;
        font-size: 55px;
      }
    }

    &__satellite {
      position: absolute;
      top: 250px;
      width: 100px;
      left: 50px;

      @include mq(md) {
        width: 150px;
        right: 280px;
        top: 40px;
        left: auto;
      }

      img {
        width: 100%;
        height: auto;        
      }
    }

    &__bird {
      position: absolute;
      top: 300px;
      left: 180px;
      width: 120px;

      @include mq(md) {
        width: 200px;
        top: 150px;
        right: 100px;
        left: auto;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &__landscape {
      width: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }

    &__button {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background: $lilaVibrant;
      color: $white;
      text-decoration: none;
      padding: 20px 0;

      .-vertical {
        height: 100%;
        width: 1px;
        background: $white;
      }

      .-arrow {
        width: 10px;
        height: 10px;
        border-right: 1px solid $white;
        border-bottom: 1px solid $white;
        transform: rotate(-45deg);
      }
    }

    &__text {
      font-weight: 700;
    }
  }

  &__about {
    position: relative;

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 80px;

      @include mq(md) {
        padding-top: 0;
        height: 90vh;

      }
    }

    &__subtitle {
      text-transform: uppercase;
      color: $darkGray;
      margin: 0;
      margin-bottom: 20px;
      font-weight: 300;
    }

    &__title {
      color: $lilaDark;
      font-weight: 700;
      margin: 0;
      margin-bottom: 40px;
      font-size: 30px;

      @include mq(md) {
        font-size: 36px;
      }
    }

    &__text {
      margin: 0;
      color: $darkGray;
      font-size: 20px;
    }

    &__button {
      margin-top: 40px;
      text-decoration: none;
      display: flex;
      align-items: center;

      span {
        width: 10px;
        height: 10px;
        border-right: 1px solid $darkGray;
        border-bottom: 1px solid $darkGray;
        transform: rotate(-45deg);
        margin-right: 20px;
      }

      p {
        color: $lilaVibrant;
        font-weight: 700;
        font-size: 22px;

        @include mq(md) {
          font-size: 18px;
        }
      }
    }

    &__image {
      margin-top: 40px;

      @include mq(md) {
        margin-top: 0;
        position: absolute;
        top: 0;
      }
      
      @include customMq(1200px) {
        left: calc(50% + 110px);
        width: calc(50% - 110px);
      }

      @include customMq(1400px) {
        left: calc(50% + 120px);
        width: calc(50% - 120px);
      }
    }

    &__wrapper {
      position: relative;
      width: 100%;
      padding-bottom: 162.6%;
      height: 0;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
    }
  }

  &__news {
    z-index: 2;
    position: relative;
    padding-bottom: 40px;
    margin-top: -300px;

    &:after {
      content: '';
      position: absolute;
      top: 300px;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      background: $lightestGray;
    }

    @include mq(md) {
      margin-top: -80px;
    }

    &__ripped-background {
      position: absolute;
      bottom: 0;
      top: 220px;
      left: 0;
      right: 0;
      width: 100%;
      
      @include mq(md) {
        top: 140px;
      }

      img {
        width: 100%;
        height: auto;
        
        @include mq(md) {

        }
      }
    }

    &__title {
      font-size: 32px;
      text-align: right;
      color: $white;
      margin-bottom: 40px;

      @include mq(md) {
        margin-bottom: 60px;
      }
    }

    &__new-container {
      @include trans;

      display: block;
      text-decoration: none;
      margin-bottom: 20px;
      
      @include mq(md) {
        margin-bottom: 60px;
      }

      &:hover {
        @include mq(md) {
          transform: translateY(-20px);
          box-shadow: 0 10px 15px 2px rgba(0,0,0,.15);
        }
      }
    }

    &__new-image {
      height: 0;
      padding-bottom: 100%;
      overflow: hidden;
      position: relative;

      img {
        position: absolute;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        
      }
    }

    &__new-content {
      background: $white;
      padding: 25px;
    }

    &__new-subtitle {
      margin: 0;
      color: $lilaVibrant;
      margin-bottom: 10px;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 14px;
    }

    &__new-title {
      margin: 0;
      color: $lilaDark;
      margin-bottom: 20px;
      font-size: 20px;
    }

    &__new-text {
      margin: 0;
      color: $darkGray;
    }

    &__button {
      text-decoration: none;
      background: $lilaVibrant;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;

      span {
        width: 10px;
        height: 10px;
        border-right: 1px solid $lightGray;
        border-bottom: 1px solid $lightGray;
        transform: rotate(-45deg);
      }
      
      p {
        margin-right: 20px;
        color: $white;
        font-weight: 700;
      }
    }
  }
}
