.encuentro {
  &__hero {
    background: linear-gradient(to bottom, $lila, $lilaDark);
    padding: 40px 0 80px 0;

    @include mq(md) {
      padding: 80px 0 10% 0;
    }

    &__subtitle {
      color: $white;
      font-size: 12px;
      font-weight: 300;
      margin: 0;
      margin-bottom: 20px; 

      @include mq(md) {
        font-size: 16px;
      }
    }

    &__title {
      color: $white;
      margin-bottom: 40px;
      margin: 0;
      font-size: 40px;
      margin-bottom: 40px;

      @include mq(md) {
        font-size: 50px;
        margin-bottom: 0; 
      }
    }

    &__text {
      margin: 0;
      line-height: 1.5;
      margin-bottom: 20px;
      color: $white;
    }
  }

  &__encuentros {
    padding: 40px 0;

    @include mq(md) {
      padding: 80px 0;
    }

    &__slide {
      margin-bottom: 3%;
    }

    &__title {
      color: $mainColor;
      font-size: 40px;

      @include mq(md) {
        font-size: 60px;
      }
    }
    
    ._slider {
      .slick-slide {
        position: relative;
        width: 80vw !important;
        margin: 0 10px;        
        @include mq(md) {
          margin: 0 20px;
        }
        
        &:after {
          content: '';
          position: absolute;
          background: $lilaDark;
          top: 14px;
          left:  0;
          bottom :0;
          right: 0;
          opacity: .7;
        }

        @include customMq(1200px) {
          width: calc(950px - 15px) !important;
        }
  
        @include customMq(1400px) {
          width: calc(1050px - 15px) !important;
        }
      }
      
      .slick-current {
        opacity: 1;

        &:after {
          display: none;
        }
      }
    }

    &__slide-container {
      background: $lilaDark;
      width: 100%;
      display: flex;
      flex-direction: column;

      @include mq(md) {
        flex-direction: row;
      }
    }

    &__slide-content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 20px;
      order: 2;
      
      @include mq(md) {
        padding: 80px;
        width: 61.5%;
        order: 1;
      }
    }

    &__slide-image {
      order: 1;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;
      overflow: hidden;

      @include mq(md) {
        width: 38.5%;
        order: 2;
        height: auto;
        padding-bottom: 0;
        overflow: visible;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: auto;

        @include mq(md) {

        }
      }
    }

    &__slide-subtitle {
      margin: 0;
      color: $lilaVibrant;
      font-size: 14px;
      margin-bottom: 10px;

      @include mq(md) {
        margin-bottom: 20px;
      }
    }

    &__slide-title {
      color: $white;
      margin: 0;
      font-size: 30px;
      margin-bottom: 20px;

      @include mq(md) {
        font-size: 40px;
      }
    }

    &__slide-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      @include mq(md) {
        justify-content: flex-start;
      }
    }

    &__slide-date,
    &__slide-location {
      display: flex;
      align-items: center;
      color: $white;
      margin-left: 10px;
      
      @include mq(md) {
        margin-right: 40px;
      }

      i {
        margin-right: 20px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 30px;
          height: 30px;
          border: 1px solid $white;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
      }

      p {
        font-size: 12px;
      }
    }

    &__slide-text {
      color: $white;
      font-size: 14px;
      line-height: 1.5;
    }
  }

  &__resena {
    padding: 40px 0;
        
    @include mq(md) {
      padding: 100px 0;
    }

    &__subtitle {
      color: $darkGray;
      font-weight: 300;
      margin: 0;
      margin-bottom: 20px;
      font-size: 12px;
    }

    &__title {
      color: $lilaDark;
      margin: 0;
      font-size: 30px;
    }

    &__text {
      color: $darkGray;
      line-height: 1.5;
    }
  }

  &__programacion {
    position: relative;
    padding-top: 100px;

    @include mq(md) {
      margin-top: -12%;
      padding-top: 250px;
    }
    
    &:after {
      content: '';
      position: absolute;
      background: $lightestGray;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      top: 50px;
      
      @include mq(md) {
        top: 400px;
      }
    }

    &__ripped-background {
      position: absolute;
      top: 0;
      width: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }
    
    &__container {
      @include trans;
      text-align: center;
      cursor: pointer;

      opacity: .4;

      &.-active {
        opacity: 1;
        cursor: default;
      }
    }

    &__group {

      width: 100%;
      position: relative;
      display: none;

      &:after {
        content: '';
        position: absolute;
        top: 30px;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        width: 1px;
        background: $darkGray; 
        z-index: 1;
      }

      &.-active {
        display: block;
      }
    }

    &__group-wrapper {
      display: flex;
      margin-bottom: 50px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 20px;
        height: 20px;
        border: 1px solid $lilaDark;
        border-radius: 50%;
        background: $white; 
        z-index: 2;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 12px;
        height: 12px;
        border: 1px solid $lilaDark;
        border-radius: 50%;
        background: $lilaDark; 
        z-index: 3;
      }
    }
    
    &__content-group {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &--left {
        margin-left: auto;
      }
    }

    &__subtitle {
      color: $darkGray;
      font-size: 12px;
      margin: 0;
      margin-bottom: 10px;
    }

    &__title {
      color: $lilaDark;
      margin: 0;
      font-size: 40px;
      margin-bottom: 40px;

      @include mq(md) {
        margin-bottom: 80px;
      }

      &--small {
        margin-bottom: 0;
        font-size: 14px;

        @include mq(md) {
          font-size: 24px;
        }
      }
    }

    &__image {
      height: 0;
      overflow: hidden;
      box-shadow: 0 5px 20px 2px rgba(0,0,0,.35);
      padding-bottom: 70%;
      width: 70%;

      @include mq(md) {
        width: 165px;
        padding-bottom: 63%;
      }

      &--left {
        margin-left: auto;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__ubicacion {
    @include mq(md) {
      display: flex;
      height: 420px;
    }

    &__map {
      position: relative;
      height: 100vw;
      
      @include mq(md) {
        width: 70%;
        height: 100%;
      }

      iframe {
        @include trans;

        position: absolute;
        opacity: 0;
        height: 100%;
        width: 100%;
        border: none;
        top: 0;
        left: 0;

        &.-active {
          opacity: 1;
        }
      }
    }

    &__locations {
      
      @include mq(md) {
        width: 30%;
      }
    }

    &__location {
      @include trans;

      background: $lilaDark;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-left: 40px;
      position: relative;
      height: 100px;
      cursor: pointer;

      @include mq(md) {
        height: 140px;
      }

      &:after {
        @include trans;

        content: '';
        position: absolute;
        top: 50%;
        width: 20px;
        height: 20px;
        border-left: 1px solid $white;
        border-bottom: 1px solid $white;
        transform: rotate(135deg) translateY(0);
        opacity: 0;
        left: 20px;

        @include mq(md) {
          transform: rotate(45deg) translateY(-50%);
        }

        @include mq(md) {
          left: 40px;
        }
      }

      &.-active {
        background: $lilaVibrant;
        padding-left: 60px;

        @include mq(md) {
          padding-left: 80px;
        }

        &:after {
          content: '';
          opacity: 1;
        }
      }
    }

    &__title {
      margin: 0;
      margin-bottom: 10px;
      color: $white;
    }

    &__text {
      margin: 0;
      color: $white;
      font-size: 12px;
    }
  }

  &__formularios {
    background: $lightestGray;
    position: relative;
    padding-top: 40px;

    @include mq(md) {
      padding-top: 100px;
    }

    &:after {
      content: '';
      position: absolute;
      background: $white;
      bottom: 0;
      left: 0;
      right: 0;
      top: 75%;
      
      @include mq(md) {
        top: 400px;
      }
    }

    &__ripped-background {
      position: absolute;
      left: 0;
      right: 0;
      top: 70%;

      @include mq(md) {
        top: 100px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &__wrapper {
      padding-bottom: 40px;
      position: relative;
      z-index: 2;

      @include mq(md) {
        display: flex;
      }
    }

    &__container {
      @include trans;

      background: $lilaDark;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding-top: 60px;
      text-decoration: none;
      
      @include mq(md) {
        width: 50%;
      }

      i {
        color: $white;
        font-size: 80px;
      }

      &:hover {
        background: $lila;

        @include mq(md) {
          box-shadow: 0 5px 20px 2px rgba(0,0,0,.35);
          transform: translateY(-20px);
        }
      }
    }

    &__title {
      color: $white;
      margin: 40px;
    }

    &__button {
      width: 80%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $lilaVibrant;
      color: $white;

      p {
        margin-right: 20px;
      }
      span {
        width: 8px;
        height: 8px;
        border-right: 2px solid $white;
        border-bottom: 2px solid $white;
        transform: rotate(-45deg);
      }
    }
  }
}

.-left {
  text-align: right;
  padding-right: 20px;

  @include mq(md) {
    padding-right: 40px;
  }
}

.-right {
  text-align: left;
  padding-left: 20px;
    
  @include mq(md) {
    padding-left: 40px;
  }
}