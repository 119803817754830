.header {
    background: $lila;
    position: relative;
    
    &__container {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $white;
    }

    &__logo {
        height: 60px;
        
        @include mq(md) {
        }

        img {
            height: 100%;
            width: auto;
        }
    }


    &__nav {
        @include trans;
        
        opacity: 0;
        overflow: hidden;
        width: 0;
        transform: translateX(-100%);
        background: linear-gradient(to bottom, $lila, $lilaDark);
        position: absolute;
        top: 101px;
        left: 0;
        height: calc(100vh - 101px);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;

        @include mq(md) {
            position: relative;
            left: auto;
            top: auto;
            width: auto;
            opacity: 1;
            overflow: visible;
            width: auto;transform: translateX(0);
            height: 60px;
            align-items: flex-end;
            justify-content: flex-end;
            background: $lila;
        }
    }

    &__list {
        display: inline-block;

        @include mq(md) {
            display: flex;
            padding-bottom: 12px;
        }
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 20px;
        }

        @include mq(md) {
            margin-left: 40px;

            &:not(:last-child) {
                margin-bottom: 0;
            }
        }

        a {
            color: $white;
            text-decoration: none;
            font-weight: 700;
            font-size: 20px;

            @include mq(md) {
                font-size: 16px;
            }
        }
    }

    $h: &;

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 21px;
        // margin: auto;
        // position: absolute;
        // right: 32px;
        // top: 30px;

        @include mq(md) {
            display: none;
        }

        span {
            background-color: $white;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            opacity: 1;
            transform: translateX(0);
            width: 100%;
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
