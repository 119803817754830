.red {
  overflow: hidden;
  &__hero {
    background: linear-gradient(to bottom, $lila, $lilaDark);

      &__container {
        padding: 40px 0;

        @include mq(md) {
        padding: 60px 0;
      }
    }

    &__title {
      color: $white;
      font-weight: 700;
      font-size: 40px;
      margin: 0;

      @include mq(md) {
        font-size: 50px;
      }
    }
  }

  &__mision {
    position: relative;

    &__container {
      height: 60vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__image {
      margin-top: 40px;

      @include mq(md) {
        margin-top: 0;
        position: absolute;
        top: 0;
      }
      
      @include customMq(1200px) {
        left: calc(50% + 110px);
        width: calc(50% - 110px);
      }

      @include customMq(1400px) {
        left: calc(50% + 120px);
        width: calc(50% - 120px);
      }
    }

    &__wrapper {
      position: relative;
      width: 100%;
      padding-bottom: 162.6%;
      height: 0;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
    }
  }

  &__vision {
    position: relative;
    z-index: 2;
    overflow: hidden;
    margin-top: -280px;

    &:after {
      content: '';
      position: absolute;
      top: 190px;
      bottom: 0;
      left: 0;
      right: 0;
      background: $lightestGray;
      z-index: -1;
    }

    @include mq(md) {
      margin-top: 0;

      &:after {
        display: none;
      }
    }

    &__ripped-background {
      position: absolute;
      left: 0;
      right: 0;
      top: 170px;
      
      @include mq(md) {
        top: 0;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &__container {
      background: $lilaVibrant;
      padding: 60px 20px;      

      @include mq(md) {
        padding: 80px;
      }
    }
  }

  &__objetivos {
    position: relative;
    z-index: 2;
    background: $lightestGray;

    &__image {
      @include mq(md) {
        position: absolute;
        width: 50%;
        top: 80px;
      }

      img {
        height: auto;
        padding-bottom: 40px;
        width: 90%;

        @include mq(md) {
          width: 100%;
          padding-bottom: 0;
        }
      }
    }

    &__container {
      padding: 80px 0 40px 0;

      @include mq(md) {
        padding: 100px 0 400px 0;
      }
    }

    &__tabs {
      display: flex;
      margin-bottom: 20px;
    }

    &__tab {
      @include trans;

      margin-right: 40px;
      font-size: 14px;
      color: $darkGray;
      position: relative;
      padding-bottom: 10px;

      &:after {
        content: '';
        @include trans;
      }

      &.-active {
        color: $lilaVibrant;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 2px;
          left: 0;
          background: $lilaVibrant;
        }
      }
    }

    &__text {
      @include trans;

      opacity: 0;
      height: 0;
      overflow: hidden;
      margin: 0;

      &.-active {
        opacity: 1;
        height: auto;
        overflow: visible;
      }
    }
  }

  &__valor {
    position: relative;
    
    @include mq(md) {
      background: $lightestGray;
      margin-top: -200px;
    }

    &__container {
      text-align: center;
      background: $white;
      position: relative;
      z-index: 3;
      padding: 80px 0;

      @include mq(md) {
        padding: 80px 150px;
      }

      &:after {
        content: '';
        position: absolute;
        left: -1000px;
        height: 100%;
        width: 1000px;
        background: $white;
        top: 0;
      }
    }
  }

  &__historia {
    position: relative;
    z-index: 2;
    padding: 250px 0 80px 0;
    margin-top: -150px;

    @include mq(md) {
      background: $lightestGray;
      padding: 400px 0 100px 0;
      margin-top: -300px;
    }
 
    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      @include mq(md) {
        transform: translateX(0);
        width: 100%;
        height: auto;
        left: 0;
      }
    }
  }
}

.-subtitle {
  color: $darkGray;
  margin: 0;
  font-weight: 300;
  margin-bottom: 10px;
}

.-title {
  color: $lilaDark;
  font-size: 28px;
  margin: 0;
  margin-bottom: 20px;

  &--white {
    color: $white;
  }
}

.-text {
  color: $darkGray;
  line-height: 1.6;

  &--white {
    color: $white;
  }

  &--purple {
    color: $lilaVibrant;
  }
}
